import { render, staticRenderFns } from "./PlanificationFamiliales.vue?vue&type=template&id=1b0d0092&scoped=true"
import script from "./PlanificationFamiliales.vue?vue&type=script&lang=js"
export * from "./PlanificationFamiliales.vue?vue&type=script&lang=js"
import style0 from "./PlanificationFamiliales.vue?vue&type=style&index=0&id=1b0d0092&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0d0092",
  null
  
)

export default component.exports